import React, { useEffect, useState, useContext } from 'react';
//import NextLink from 'next/link';
//import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { Box, Button, Divider, Drawer, Typography, Link as MaterialLink, useMediaQuery, Collapse } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { ChartBar as ChartBarIcon } from '../../icons/chart-bar';
import { Cog as CogIcon } from '../../icons/cog';
import { Lock as LockIcon } from '../../icons/lock';
import { Selector as SelectorIcon } from '../../icons/selector';
import { ShoppingBag as ShoppingBagIcon } from '../../icons/shopping-bag';
import { User as UserIcon } from '../../icons/user';
import { UserAdd as UserAddIcon } from '../../icons/user-add';
import { Users as UsersIcon } from '../../icons/users';
import { XCircle as XCircleIcon } from '../../icons/x-circle';
import { NavItem } from '../NavItem/NavItem';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import FacebookIcon from '@mui/icons-material/Facebook';
import InsightsIcon from '@mui/icons-material/Insights';
import InstagramIcon from '@mui/icons-material/Instagram';
import FilterIcon from '@mui/icons-material/Filter';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from 'react-router-dom';
import WarehouseIcon from '@mui/icons-material/Warehouse';

import {Link, useLocation} from 'react-router-dom'

import CmsContext from '../../store/cms-context';
import Google from '@mui/icons-material/Google';
import { LocalShipping, Warehouse } from '@mui/icons-material';

import ReviewsIcon from '@mui/icons-material/Reviews';

import StoreIcon from '@mui/icons-material/Store';


const items = [
  {
    href: '/',
    icon: (<ChartBarIcon fontSize="small" />),
    title: 'Dashboard',
    sub_items:[],
    key:'dashboard'
  },
  {
    href: '/products',
    icon: (<ShoppingBagIcon fontSize="small" />),
    title: 'Products',
    sub_items:[{href:'/list', title:'List'},{href:'/styles', title:'Styles'},{href:'/create', title:'Create'}, {href:'/scheduled', title:'Scheduled'}],
    key:'products'
  },
  {
    href: '/collections',
    icon: (<LibraryBooksIcon fontSize="small" />),
    title: 'Collections',
    sub_items:[{href:'/list', title:'List'},{href:'/create', title:'Create'}],
    key:'collections'
  },
  {
    href: '/google',
    icon: (<Google fontSize="small" />),
    title: 'Google',
    sub_items:[{href:'/ads', title:'Ads'}, {href:'/manage', title:'Manage'}, {href:'/search', title:'Search Console'}],
    key:'google'
  },
  {
    href: '/facebook',
    icon: (<FacebookIcon fontSize="small" />),
    title: 'Facebook',
    sub_items:[{href:'/configs', title:'Configs'}, {href:'/ads', title:'Ads'}, {href:'/ad_groups', title:'Ad Groups'}, {href:'/breakdowns', title:'Breakdowns'}, {href:'/sync', title:'Sync'}, {href:'/tags', title:'Tags'}],
    key:'facebook'
  },
  {
    href: '/instagram',
    icon: (<InstagramIcon fontSize="small" />),
    title: 'Instagram',
    sub_items:[{href:'/tags', title:'Tags'}, {href:'/shopify', title:'Shopify'}],
    key:'instagram'
  },
  {
    href: '/storage',
    icon: (<FilterIcon fontSize="small" />),
    title: 'Storage',
    sub_items:[{href:'/images', title:'Images'}],
    key:'storage'
  },
  {
    href: '/shopify',
    icon: (<StoreIcon fontSize="small" />),
    title: 'Shopify',
    sub_items:[{href:'/preorder', title:'Preorder'}, {forward_ref:'/bring_it_back/configs' , href:'/bring_it_back', title:'Bring it back', sub_items:[{href:'/configs', 'title': "Configs"}, {href:'/targets', 'title': "Targets"}]}, {forward_ref:'/products/sync' , href:'/products', title:'Products', sub_items:[{href:'/sync', 'title': "Sync"}, {href:'/discounts', 'title': "Discounts"}]}, {forward_ref:'/conversion/rates' , href:'/conversion', title:'Conversion', sub_items:[{href:'/rates', 'title': "Rates"}]}, {forward_ref:'/checkouts/post_purchase' , href:'/checkouts', title:'Checkouts', sub_items:[{href:'/post_purchase', 'title': "Post Purchase"}]}],
    key:'shopify'
  },
  {
    href: '/inventory',
    icon: (<Inventory2Icon fontSize="small" />),
    title: 'Inventory',
    sub_items:[{href:'/reports', title:'Reports'}],
    key:'inventory'
  },
  {
    href: '/shipping',
    icon: (<LocalShipping fontSize="small" />),
    title: 'Shipping',
    sub_items:[{href:'/services', title:'Services'}, {href:'/reports', title:'Reports'}],
    key:'shipping'
  },
  {
    href: '/customers',
    icon: (<UsersIcon fontSize="small" />),
    title: 'Customers',
    sub_items:[{href:'/reviews_comments', title:'Reviews & Comments'}],
    key:'customers'
  },
  {
    href: '/warehouse',
    icon: (<Warehouse fontSize="small" />),
    title: 'Warehouse',
    sub_items:[{href:'/mintsoft', title:'Mintsoft'}],
    key:'warehouse'
  },
  {
    href: '/analytics',
    icon: (<AnalyticsIcon fontSize="small" />),
    title: 'Analytics',
    sub_items:[{href:'/sales', title:'Sales'}],
    key:'analytics'
  },
  {
    href: '/settings',
    icon: (<CogIcon fontSize="small" />),
    title: 'Settings',
    sub_items:[],
    key:'settings'
  }
];

export const DashboardSidebar = (props) => {

  const navigate = useNavigate();


  const [selected_nav_item, set_selected_nav_item] = React.useState('dashboard')

  const cmsContext = useContext(CmsContext);

  const { open, onClose } = props;
  //const router = useRouter();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
    defaultMatches: true,
    noSsr: false
  });

  const location = useLocation();

  var path = location.pathname.split('/')[1];

  if(path.length === 0){
    path = "dashboard"
  }

  // console.log('path', location.pathname.split('/')[1])

  useEffect(() =>{

   // console.log('settings path', path)
    set_selected_nav_item(path)

  },[path])


  const content = (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <div>
          <Box sx={{ paddingTop: 3,paddingBottom:3, paddingLeft:4.5, paddingEnd:3 }}>

              
            <Avatar src="/static/images/datasaur_logo_computer.png" style={{'width': 200,'height':200}}/>


          </Box>

          <Box sx={{ px: 2 }}>
            <Box
              sx={{
                alignItems: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.04)',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between',
                px: 3,
                py: '11px',
                borderRadius: 1
              }}
            >
              <div>
                <Typography
                  color="inherit"
                  variant="subtitle1"
                >
                  Datasaur
                </Typography>
                <Typography
                  color="neutral.400"
                  variant="body2"
                >
                  CMS
                  {' '}
                  : Admin
                </Typography>
              </div>
            </Box>
          </Box>
        </div>
        <Divider
          sx={{
            borderColor: '#2D3748',
            my: 3
          }}
        />
        <Box sx={{ flexGrow: 1 }}>
        
          {items.map((item, index) => (
            <div key={index}>
            <NavItem
              key={index}
              set_selected_nav_item={set_selected_nav_item}
              item={item}
              icon={item.icon}
              href={item.href}
              item_key={item.key}
              item_title={item.title}
              title={item.title}
              type="item"
              is_sub_item_sub="false"
              is_active={path === item.key}
            />
             <Collapse in={selected_nav_item === item.key ? true : false} timeout="auto" unmountOnExit>

            {

            item.sub_items.map((sub_item, index1) => {

              var is_active = false

              var has_sub_items = "false"

              is_active = location.pathname === item.href + sub_item.href
              
              if(sub_item.sub_items){

                has_sub_items = "true"

                sub_item.sub_items.forEach((temp_item) => {

                  if(location.pathname === item.href + sub_item.href + temp_item.href){
                    is_active = true
                  }

                })

              }
 
              return(

              <React.Fragment key={index1}>
              
              <NavItem
              key={index1}
              set_selected_nav_item={set_selected_nav_item}
              href={item.href + sub_item.href}
              item={item}
              sub_item={sub_item}
              has_sub_items={has_sub_items}
              title={sub_item.title}
              item_key={item.key}
              item_title={item.title}
              is_active={is_active}
              type="sub_item"
              is_sub_item_sub="false"
            >

            </NavItem>

            {is_active && sub_item.sub_items ? sub_item.sub_items.map((sub_item_sub, index2) => {

              return(
                
              <NavItem
              key={index2}
              set_selected_nav_item={set_selected_nav_item}
              href={item.href + sub_item.href + sub_item_sub.href}
              item={item}
              title={sub_item_sub.title}
              has_sub_items="false"
              item_key={item.key}
              item_title={item.title}
              is_active={(location.pathname === item.href + sub_item.href + sub_item_sub.href) ? true : false}
              type="sub_item"
              is_sub_item_sub="true"
              >

              </NavItem>

              )
              }
              )
              : null}

            </React.Fragment>

            )

            }

            )

            }

           </Collapse>
          </div>
          ))
          }
        </Box>
        <Divider sx={{ borderColor: '#2D3748' }} />
        <Box
          sx={{
            px: 2,
            py: 3
          }}
        >
          <Typography
            color="neutral.100"
            variant="subtitle2"
          >
            Shopify Stores
          </Typography>
          
          <Box
            sx={{
              display: 'flex',
              mt: 2,
              mx: 'auto',
              width: '160px',
              '& img': {
                width: '100%'
              }
            }}
          >
            <img
              alt="Go to pro"
              src="/static/images/sidebar_pro.png"
            />
          </Box>
          {
            cmsContext.current_shop.connected_stores.map((store, index) => {

              return(

                <MaterialLink
                key={index}
            href={"https://" + store.store_domain + "/admin"}
            target="_blank"
            style={{textDecorationColor:'white'}}
          >
            <Button
              color="secondary"
              endIcon={(<OpenInNewIcon />)}
              fullWidth
              sx={{ mt: 2 }}
              variant="contained"
            >
              {store.name}
            </Button>
          </MaterialLink>

              )
              
            })
          }
        </Box>
      </Box>
    </>
  );

  if (lgUp) { 
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'neutral.900',
            color: '#FFFFFF',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          color: '#FFFFFF',
          width: 280
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};
